import { withRouter } from 'next/router';
import React from 'react';

import NotFound from '../components/NotFound';

const RootError = () => {
  return <NotFound />;
};

export default withRouter(RootError);
